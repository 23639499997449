import template from './tickets.html';
require('./tickets.less');
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.canContinueAfterSelectingSeats = SeatsIO.canContinueAfterSelectingSeats
        vm.SeatsIO = SeatsIO
        vm.seatsViolations = []

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        if(vm.fourStepWizard && !vm.eventFilter)
            $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.firstStepTickets = function(tid){
            let secondStepTickets = ['0675f8fe-c467-4f01-b7d0-dfff0a18ffef','9a010443-cc07-44dc-a1c9-e62400041979','fd49ce22-1a80-4d3c-b683-399bebb0cdc9','f1ca4c03-576b-490a-b588-18dfb08723fe','a3cec7b6-6d12-491c-bf62-2a2dcf3ea848','8e1cf855-f2ff-4c5b-9132-d2664d62b1e0','e77911f4-71b4-4649-8468-8b0cc099da59','5d913b9d-3ef4-45c0-b67d-50c15494bcbe','6289c31f-210d-4563-a600-b672ded2f62d','3de959e4-4074-4db7-bf0f-1aa77af4152a','c4e468d5-8763-4ef5-8382-07603299d015','8d8d16c3-5a3b-4444-9873-79c5f0b6a51b','5f7e52b7-1695-4f1a-bb1a-808d3980f521','76bb444e-e007-4fbc-8337-964d56e21122','db5f0e22-ec1d-4130-adff-9a00d8d3d8c5','516b66e7-07cb-4dd3-aada-efd0caa5cb2a','9db57aab-5723-40ba-be61-57d3ead7fd0e'];
            let firstStepTickets = ['29fb040d-effc-4be7-a3b2-d6ff884ac558','cba9db36-f9d6-4fee-9aa8-2e0d915359b5','74da0332-c339-4630-9a33-83b7f874aab9','7eb75fe2-4e7b-45cc-9c6b-9c30a83c4d0a','91b39423-b699-48d7-8159-7736d26d8c09','0ee7ec72-45e4-4190-9e88-9452d14ce684','c45085b7-dee8-4be4-aa6a-3f280d714fbb','3de959e4-4074-4db7-bf0f-1aa77af4152a','c4e468d5-8763-4ef5-8382-07603299d015','db5f0e22-ec1d-4130-adff-9a00d8d3d8c5','516b66e7-07cb-4dd3-aada-efd0caa5cb2a','9db57aab-5723-40ba-be61-57d3ead7fd0e'];
            return firstStepTickets.includes(tid);
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            if (_.flatten(_.values(order.reservations)).length < 1)
                UIMessages.push('common.shop.order.empty');
            else
                $state.go('eventix.shop.upgrades', { shopId: shop.guid });
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
        };

        /**
         * Watch the SeatsIO service's canContinueAfterSelectingSeats property to disable the continue button.
         * @param {boolean} newValue the new value
         */
        $scope.$watch('vm.SeatsIO.canContinueAfterSelectingSeats', (newVal) => {
            vm.canContinueAfterSelectingSeats = newVal
        }, true)

        /**
         * Watch the SeatsIO service's violations property to refresh the violations of the current selection.
         * @param {Array<string>} newValue the new value
         */
        $scope.$watch('vm.SeatsIO.violations', (newVal) => {
            vm.seatsViolations = newVal
        }, true)
    }).name;
