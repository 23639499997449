import template from './download.html';
import header from '../header.html';
import './download.less';
// noinspection ES6UnusedImports
import sweetAlert from 'angular-sweetalert';
// noinspection ES6UnusedImports
import sweetAlertCss from 'sweetalert/lib/sweet-alert.css';

export default angular.module('eventix.order.download', ['oitozero.ngSweetAlert'])
    .config(function($stateProvider, GoogleTagManagerProvider) {
        $stateProvider.state('eventix.download', {
            url: '/download/:guid?order_ticket_id',
            views: {
                main: {
                    controller: 'OrderDownloadController',
                    controllerAs: 'vm',
                    templateUrl: template,
                },
                header: {
                    controller: 'OrderStatusHeaderController',
                    controllerAs: 'vm',
                    templateUrl: header,
                },
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$, $state) {
                    return Order.getPublic($transition$.params().guid).then(order => {
                        // Load Tag as early as possisble
                        if (!(_.get($state.params, 'gtm', 1) == 0 || _.get($state.params, 'gtm', 1) == '0') && order.shop.google_tag) {
                            GoogleTagManagerProvider.addContainer(order.shop.google_tag);
                        }
                        return order;
                    });
                },
                shop: function($http, AskAPI, Shop, Currencies, order) {
                    // determine to load shop or not -> only needed for personalisation atm
                    if(order.is_complete)
                        return null;

                    let shopId = order.shop_id;
                    let url = '/' + shopId + '/data';
                    if (typeof USE_CACHED !== 'undefined' && USE_CACHED == 1) {
                        url = 'https://ev3b.s3-eu-west-1.amazonaws.com/shops/' + shopId + '/' + shopId + '.json';
                    }
                    return $http.get(url)
                        .then(response => {
                            return AskAPI.restore(response);
                        }).then(() => {
                            let shop = Shop.cached.first();
                            Currencies.use(shop.currency);
                            return shop;
                        });
                },
            },
        });
    })
    .controller('OrderDownloadController', function($state, $scope, $q, $translate, $rootScope, order, $timeout, UIMessages, ErrorRejector, $window, VisitorData, shop, PaymentMethod, MetaData, $http, Order, Company, Whitelabel, SweetAlert, LaravelValidationRules) {
        const vm = this;

        const refundStatii = ['refunding', 'moving'];
        const refundStatiiDisableAndSelect = ['refunding'];

        vm.messages = UIMessages;
        vm.order = order;
        vm.shopLink = FANCY_SHOP_ROOT + '/' + order.shop_id;
        vm.closedLoopUrl = CLOSED_LOOP_URL + '/' + order.guid;

        vm.returnProtectTermsUrl = REFUND_PROTECT_TERMS;
        vm.returnProtectClaimUrl = REFUND_PROTECT_CLAIM;
        vm.returnProtectMoreInfoUrl = REFUND_PROTECT_MOREINFO;

        vm.shop = order.shop;
        vm.isMobile = /mobile/i.test(_.get(VisitorData, 'userAgent.device.type', ''));
        vm.isApple = /mac|ios/i.test(_.get(VisitorData, 'userAgent.os.name', ''));
        vm.downloadReady = false;
        vm.status = 'pending';
        vm.supportValues = {
            email: $scope.branding.email || 'tickets@eventix.nl',
        };
        vm.cancel = (e) => e.preventDefault() && e.stopPropagation();
        vm.payment = _.first(vm.order.payments);
        vm.currency = _.get(vm.payment, 'currency', DEFAULT_CURRENCY);
        vm.company = Company.cached.first();
        vm.whitelabel = Whitelabel.cached.first();

        vm.forOrderTicketId = _.get($state.params, 'order_ticket_id');
        vm.currentTicket;

        vm.metadata = MetaData.cached;
        vm.remembers = {};

        vm.refundSettings = newRefundSettings();
        vm.requestRefund = requestRefund;

        function poll() {
            fetchOrder();
        }

        poll();

        vm.startPersonalisation = startPersonalisation;

        function startPersonalisation() {
            _.first(_.filter($scope.accordion.groups, g => !(g.panelClass).includes('shoptionals'))).toggleOpen();
        }

        vm.updateTicketMetaData = updateTicketMetaData;

        function updateTicketMetaData(ticket, sendable) {
            if (sendable) {
                ticket.canSend = true;
            }
            if (ticket.has_details) {
                // all metadata
                ticket.metadata = ticket.originalMetadata;
            } else {
                // firstname / lastname / email
                ticket.metadata = _.filter(ticket.originalMetadata, function(md) {
                    return md.metadata.name === 'first_name' || md.metadata.name === 'last_name' || md.metadata.name === 'email';
                });
            }
        }

        vm.saveTicket = saveTicket;

        function saveTicket(ticket, sendMail) {
            if (!ticket.acceptTerms && ticket.has_details) {
                if ((vm.shop.company_terms || vm.shop.global_terms) && !vm.acceptTerms) {
                    return UIMessages.push('common.notice.acceptTerms');
                }
            }

            let validateMetaData = {};
            _.forEach(ticket.metadata, function(tmd) {
                validateMetaData[tmd.metadata_id] = _.get(vm.metadata, tmd.metadata_id);
            });
            let errors = LaravelValidationRules.validate(_.filter(validateMetaData), ticket.metadataValues);

            if (_.some(errors, 'length')) {
                _.forEach(errors, function(error) {
                    _.forEach(error, function(msg) {
                        UIMessages.push(msg);
                    });
                });

                return $q.reject(errors);
            }

            let ticketMetaDataValues = _.keyBy(ticket.metadata, 'metadata_id');
            let dirties = _.filter(_.map(ticket.metadataValues, (value, guid) => {
                let ticketMd = ticketMetaDataValues[guid];

                if (ticketMd) {
                    if (value === ticketMd.value) {
                        return null;
                    }

                    return {
                        order_metadata_id: ticketMd.guid,
                        metadata_id: guid,
                        value: value,
                    };
                }

                return null;
            }));

            vm.busy = true;

            let data = {values: dirties, send_email: sendMail};

            return $http.put(`/personalize/ticket/${ticket.guid}`, data)
                .then((response) => {
                    vm.remembers[ticket.guid] = ticket;
                    if (sendMail) {
                        SweetAlert.swal({
                            title: 'Uitnodiging verstuurd',
                            text: '',
                            type: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                    } else {
                        SweetAlert.swal({
                            title: 'Ticket gepersonaliseerd',
                            text: '',
                            type: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                    }

                    vm.busy = false;
                    fetchOrder();
                    $scope.$evalAsync();
                }, error => {
                    vm.busy = false;
                    UIMessages.push((error.data.error_description ? error.data.error_description : 'Error'));
                });
        }

        function completeOrderInfo(order) {
            vm.order = order;
            vm.status = order.status;
            vm.downloadReady = order.download_ready;
            let timestamp = Math.floor(Date.now() / 1000);

            if (!order.tickets) {
                return;
            }

            let isOrderRetrievable = true;

            order.tickets = _.map(order.tickets, ticket => {
                ticket.metadataValues = {};
                _.forEach(ticket.metadata, function(md) {
                    ticket.metadataValues[md.metadata_id] = md.value;
                });
                ticket.errors = {};
                ticket.isopen = false;
                ticket.has_details = true;
                ticket.originalMetadata = ticket.metadata;
                ticket.acceptTerms = false;
                ticket.canSend = true;

                if (vm.remembers.hasOwnProperty(ticket.guid)) {
                    ticket.canSend = false;
                    ticket.has_details = vm.remembers[ticket.guid].has_details;
                    updateTicketMetaData(ticket, false);
                }

                ticket.isRetrievable = true;
                if (ticket.retrievable_after && timestamp < ticket.retrievable_after) {
                    ticket.isRetrievable = false;
                    isOrderRetrievable = false;
                    ticket.retrievable_after_readable = moment.unix(ticket.retrievable_after).format('YYYY-MM-DD HH:mm');
                }

                ticket.canBeDownloaded = ticket.is_complete && _.isNil(ticket.invalidated_since);

                if (ticket.guid === vm.forOrderTicketId) {
                    vm.closedLoopUrl = vm.closedLoopUrl + '?tickets[]=' + vm.forOrderTicketId;
                    vm.currentTicket = ticket;
                }

                ticket.hasOptionals = false;
                ticket.eventName = ticket.ticket.event.name;

                let firstDate = null;

                _.forEach(ticket.products, function(product) {
                    ticket.hasOptionals = ticket.hasOptionals && product.is_optional;

                    _.forEach(product.product.event_dates, eventDate => {
                        const start = moment(eventDate.start);

                        if (!firstDate || start.isBefore(firstDate)) {
                            firstDate = moment(eventDate.start);
                        }
                    });
                });

                if (firstDate) {
                    ticket.eventName += ` (${firstDate.format('YYYY-MM-DD HH:mm')})`;
                }

                return ticket;
            });

            vm.order.canBeDownloaded = !!vm.order.is_complete && _.isEmpty(_.reject(order.tickets, 'canBeDownloaded'));
            vm.order.isRetrievable = !!isOrderRetrievable;
            vm.order.retrievable_after = _.get(_.maxBy(order.tickets, 'retrievable_after'), 'retrievable_after', null);
            vm.order.retrievable_after_readable = moment.unix(order.retrievable_after).format('YYYY-MM-DD HH:mm');

            loadRefundProduct();

            determineRefundStatii();
        }

        function loadRefundProduct(){
            _.forEach(vm.order.products, function(product){
                product.is_refund_protect = false;
                if(product.product.class === 'RefundProtect'){
                    product.is_refund_protect = true;
                }
            });
        }

        function fetchOrder() {
            return $http.get(ORDER_API_ROOT + `/order/${vm.order.guid}`)
                .then(res => Order.new(res.data))
                .then(order => {
                    completeOrderInfo(order);
                })
                .catch(error => {
                    if (error.message === 'processing') {
                        vm.status = 'pending';
                        completeOrderInfo(order);
                        $timeout(poll, 5000);
                    } else if (error.message === 'cancelled') {
                        vm.status = 'cancelled';
                        UIMessages.push('common.shop.order.status_cancelled');
                    } else if (_.isString(error.message)) {
                        UIMessages.push(error.message);
                    } else if (_.isString(error)) {
                        UIMessages.push(error);
                    } else {
                        UIMessages.push('common.notice.error');
                    }
                });
        }

        function newRefundSettings() {
            return {
                showRefunds: false,

                allTicketsRefundable: false,
                someTicketsRefundable: false,
                noTicketsRefundable: true,

                offerDonate: false,
                donateOptions: [],
                tickets: [],

                refundStatii: {},
                nextStatusUntil: null,
                nextStatusUntilHuman: null,

                shouldRefundFullOrder: false,
                shouldDonate: undefined,
                someTicketsHaveChoice: false,

                includeFees: false,
                includePayment: false,

                acceptTerms: false,

                brandingValues: {
                    whitelabel: SHOP_BRAND_NAME || 'Eventix',
                    until: null,
                },

                toggleAllTickets: toggleRefundForAllTickets,
                toggleOneTicket: toggleRefundForOneTicket,
            };
        }

        function determineRefundStatii() {
            let showRefunds = false;

            let allTicketsRefundable = true;
            let someTicketsRefundable = false;
            let noTicketsRefundable = vm.status !== 'paid' || !!vm.forOrderTicketId || !!vm.order.invalidated_since;

            let someTicketsHaveChoice = false;

            const enabledRefundStatii = {};

            const tickets = [];

            _.forEach(vm.order.tickets, ticket => {
                const ticketSpec = {
                    ticket: ticket,

                    isRefundable: false,
                    payoutProfile: null,
                    refundStatus: null,

                    shouldRefund: false,
                    disableChoice: true,
                    statusUntil: ticket.ticket.event.status_until ? moment(ticket.ticket.event.status_until) : null,
                };

                if (refundStatii.includes(ticket.ticket.event.status) && (!ticketSpec.statusUntil || ticketSpec.statusUntil.isAfter(moment()))) {
                    showRefunds = true;
                }

                if (refundStatii.includes(ticket.ticket.event.status) && _.isNil(ticket.invalidated_since) && (!ticketSpec.statusUntil || ticketSpec.statusUntil.isAfter(moment()))) {
                    // Refund of ticket is allowed
                    someTicketsRefundable = true;
                    ticketSpec.isRefundable = true;
                    ticketSpec.refundStatus = ticket.ticket.event.status;
                    _.set(enabledRefundStatii, ticket.ticket.event.status, true);

                    ticketSpec.payoutProfile = _.get(ticket, 'ticket.event.payout_profile', {});

                    if (refundStatiiDisableAndSelect.includes(ticket.ticket.event.status)) {
                        ticketSpec.shouldRefund = true;
                    } else {
                        someTicketsHaveChoice = true;
                        ticketSpec.disableChoice = false;
                    }
                } else {
                    // Refund of ticket is not allowed
                    allTicketsRefundable = false;
                }

                tickets.push(ticketSpec);
            });

            const allStatusUntils = _.filter(_.map(tickets, ticketSpec => (ticketSpec.isRefundable && ticketSpec.statusUntil) ? ticketSpec.statusUntil : null));
            const nextStatusUntil = allStatusUntils.length ? moment.min(allStatusUntils) : null;

            allTicketsRefundable = !noTicketsRefundable && allTicketsRefundable;
            someTicketsRefundable = !noTicketsRefundable && someTicketsRefundable;
            noTicketsRefundable = noTicketsRefundable || !someTicketsRefundable;

            let allTicketsCanDonate = true;
            let someTicketsCanDonate = false;
            const allDonateOptions = [];

            let canVoucher = true;
            let canRefund = true;
            let defaultRefundOption;
            const allMultipliers = _.filter(_.map(tickets, ticketSpec => _.get(ticketSpec.payoutProfile, 'voucher_multiplier', 1)));
            const maxVoucherMultiplier =  allMultipliers.length ? _.max(allMultipliers) : null;

            let includeFees = true;
            let includePayment = true;

            _.forEach(tickets, ticketSpec => {
                if (!ticketSpec.isRefundable) {
                    // Ignore tickets that can not be refunded anyway.
                    return;
                }

                if (ticketSpec.payoutProfile) {
                    if(!ticketSpec.payoutProfile.refundable_by_voucher){
                       canVoucher = false;
                    }
                    if(!ticketSpec.payoutProfile.refundable_by_payment){
                        canRefund = false;
                    }
                    // defaultRefundOption = ticketSpec.payoutProfile.refundable_default == 'payment' ? 'refund' : 'voucher'; // yep random / last
                }

                if (ticketSpec.payoutProfile && ticketSpec.payoutProfile.refund_donate_enabled) {
                    someTicketsCanDonate = true;

                    let profileDonateOptions = '' + _.get(ticketSpec.payoutProfile, 'refund_donate_options', '');
                    profileDonateOptions = _.filter(_.map(profileDonateOptions.split(','), _.trim));

                    // If no options were available, this will automatically result in no donation option!.
                    allDonateOptions.push(profileDonateOptions);
                } else {
                    allTicketsCanDonate = false;
                }

                if (!ticketSpec.payoutProfile || !ticketSpec.payoutProfile.refund_fees) {
                    includeFees = false;
                }

                if (!ticketSpec.payoutProfile || !ticketSpec.payoutProfile.refund_transaction_fees) {
                    includePayment = false;
                }
            });

            const donateOptions = _.spread(_.intersection)(allDonateOptions);

            if (donateOptions && donateOptions.length) {
                donateOptions.unshift(null);
            }

            _.assign(vm.refundSettings, {
                showRefunds: showRefunds,

                allTicketsRefundable: allTicketsRefundable,
                someTicketsRefundable: someTicketsRefundable,
                noTicketsRefundable: noTicketsRefundable,

                offerDonate: allTicketsCanDonate && someTicketsCanDonate && _.size(donateOptions) > 0,
                donateOptions: donateOptions,
                tickets: tickets,

                refundStatii: enabledRefundStatii,
                nextStatusUntil: nextStatusUntil,
                nextStatusUntilHuman: nextStatusUntil ? nextStatusUntil.format('YYYY-MM-DD HH:mm') : null,

                shouldRefundFullOrder: someTicketsRefundable && _.every(tickets, t => !t.isRefundable || t.shouldRefund),
                shouldDonate: undefined,
                someTicketsHaveChoice: someTicketsHaveChoice,

                includeFees: includeFees,
                includePayment: includePayment,
                hasRefundChoice: someTicketsRefundable && canVoucher && canRefund,
                refundOptions: ['voucher', 'refund'],
                // refundChoice: defaultRefundOption,
                maxVoucherMultiplier: maxVoucherMultiplier*100
            });

            if(canVoucher && !canRefund){
                vm.refundSettings.refundChoice = 'voucher'
            }
            if(!canVoucher && canRefund){
                vm.refundSettings.refundChoice = 'refund'
            }

            vm.refundSettings.brandingValues.until = vm.refundSettings.nextStatusUntilHuman;
        }

        function toggleRefundForAllTickets() {
            _.forEach(vm.refundSettings.tickets, ticketSpec => {
                if (!ticketSpec.disableChoice) {
                    ticketSpec.shouldRefund = ticketSpec.isRefundable && !vm.refundSettings.shouldRefundFullOrder;
                }
            });

            vm.refundSettings.shouldRefundFullOrder = vm.refundSettings.someTicketsRefundable && _.every(vm.refundSettings.tickets, t => !t.isRefundable || t.shouldRefund);
        }

        function toggleRefundForOneTicket(ticketSpec) {
            if (!ticketSpec.disableChoice) {
                ticketSpec.shouldRefund = ticketSpec.isRefundable && !ticketSpec.shouldRefund;
            }

            vm.refundSettings.shouldRefundFullOrder = vm.refundSettings.someTicketsRefundable && _.every(vm.refundSettings.tickets, t => !t.isRefundable || t.shouldRefund);
        }

        function requestRefund() {
            if (!_.filter(vm.refundSettings.tickets, 'shouldRefund').length) {
                return ErrorRejector.handle('Select tickets to refund');
            }

            if (vm.refundSettings.offerDonate && vm.refundSettings.shouldDonate === undefined) {
                return ErrorRejector.handle('Pick a donation option');
            }

            if (!vm.refundSettings.acceptTerms) {
                return ErrorRejector.handle('Terms should be accepted');
            }

            if (vm.busy) {
                return ErrorRejector.handle('System busy');
            }

            vm.busy = true;

            const returnItems = _.filter(_.flatMap(vm.refundSettings.tickets, ticketSpec => {
                if (!ticketSpec.isRefundable || !ticketSpec.shouldRefund) {
                    return null;
                }

                const items = _.filter(_.map(ticketSpec.ticket.products, product => {
                    if (!_.isNil(product.invalidated_since)) {
                        return null;
                    }

                    return {
                        guid: product.guid,
                        type: 'product',
                    };
                }));

                items.unshift({
                    guid: ticketSpec.ticket.guid,
                    type: 'ticket',
                });

                return items;
            }));

            if (vm.refundSettings.allTicketsRefundable && vm.refundSettings.shouldRefundFullOrder && vm.refundSettings.includePayment) {
                returnItems.unshift({
                    guid: vm.payment.guid,
                    type: 'payment',
                });
            }

            const data = {
                tickets: [],
                receiver: {
                    email: vm.order.email,
                    firstname: vm.order.firstName,
                    lastname: vm.order.lastName,
                    locale: vm.order.locale,
                },
                paymentProvider: vm.payment.payment_method_id,
                currency: vm.currency,
                return: {
                    order_id: vm.order.guid,
                    // entire_order: vm.refundSettings.shouldRefundFullOrder, // We decided not to do this as it does, as we'd like to be as explicit as possible (@chris, @peter)
                    includes_fees: vm.refundSettings.includeFees,
                    items: returnItems,
                    to_voucher: (vm.refundSettings.refundChoice == 'voucher'),
                },
            };

            const donatePercentage = _.toNumber(vm.refundSettings.shouldDonate);

            if (!_.isNaN(donatePercentage) && donatePercentage) {
                data.return.arbitrary = {};
                data.return.arbitrary.percentage = donatePercentage;
                data.return.arbitrary.vat = 0; // A Gift is always free of VAT (In NL)
            }

            console.warn('About to request refund', data);

            return $http.post(SHOP_API_ROOT + `/${vm.order.shop_id}/order`, data)
                .then(() => {
                    UIMessages.push({
                        type: 'success',
                        message: 'Refund request successful',
                    });

                    vm.refundSettings = newRefundSettings();

                    return fetchOrder();
                })
                .then(order => {
                    SweetAlert.swal({
                        title: $translate.instant('common.notice.saved'),
                        text: $translate.instant('common.shop.order.refund.notice.sent'),
                        type: 'success',
                        timer: 3500,
                        showConfirmButton: true,
                    });

                    return order;
                })
                .catch(error => {
                    ErrorRejector.handle(error);
                    parseOrderErrorResponse.call(_.cloneDeep(data), error, data, []);
                    return $q.reject(error);
                })
                .finally(() => {
                    vm.busy = false;
                });
        }

        function parseOrderErrorResponse(errorResponse, requestData, reservations) {
            const om = this;

            let errorObj = _.get(errorResponse, 'data.errors');

            _.forEach(errorObj, (e) => {
                let reservation = _.find(reservations, {reservation: e.reservation});
                let subjectType = _.has(e, 'parentOf') ? _.get(e, 'parentOf.subjectType') : _.get(e, 'subjectType');
                let metaGuid, messages;

                switch (subjectType) {
                case 'Product':
                    let productGuid = e.parentOf.guid;

                    metaGuid = e.parentOf.parentOf.guid;
                    messages = reservation.$errors[productGuid][metaGuid] || [];

                    let meta = MetaData.cached[metaGuid];
                    let reason = _.get(e, 'parentOf.parentOf.reason');

                    if (typeof reason === 'string') {
                        let attribute = $translate.instant(meta.translateName);

                        messages.push($translate.instant(e.parentOf.parentOf.reason, {attribute: attribute}));
                    } else {
                        let reasonMessages = _.map(reason, r => {
                            let attribute = $translate.instant(meta.translateName);
                            return $translate.instant(r, {attribute: attribute});
                        });

                        messages = messages.concat(reasonMessages);
                    }

                    _.set(reservation.$errors, [productGuid, metaGuid], messages);

                    break;

                case 'MetaData':
                    metaGuid = e.parentOf.guid;
                    messages = reservation.$errors[metaGuid] || [];

                    let attribute = $translate.instant(meta.translateName);

                    messages.push($translate.instant(e.parentOf.reason, {attribute: attribute}));
                    reservation.$errors[metaGuid] = messages;

                    break;

                case 'Payment':
                    UIMessages.push(e.reason);

                    om.paymentErrors[e.parentOf.guid] = [$translate.instant(e.reason)];

                    let method = PaymentMethod.cached[e.parentOf.guid];

                    if (_.has(method, 'stripe_public_key')) {
                        $rootScope.$broadcast('stripe_payment_failed', e.reason);
                    }

                    break;

                case 'Ticket':
                    if (e.reason === 'validation.extra.reservation.expired') {
                        $rootScope.$broadcast('reservation_expired');
                    } else {
                        console.error(new Error(`Couldn't process error: ${JSON.stringify(e)}`));
                    }

                    break;

                case 'Coupon':
                    ErrorRejector.handle(e.reason);

                    break;

                default:

                    console.error(new Error(`Couldn't process error: ${JSON.stringify(e)}`));

                    break;
                }
            });
            errorObj = _.get(errorResponse, 'data.error_description');
            _.forEach(errorObj, (messages, path) => {
                if (/receiver/.test(path)) {
                    let receiverObject = path.replace(/receiver./g, '');

                    if (!_.isArray(om.receiver.$errors[receiverObject])) {
                        om.receiver.$errors[receiverObject] = [];
                    }

                    om.receiver.$errors[receiverObject].push(messages);
                }

                if (!/^tickets[\.\d]+products[\.\d]+metaData/.test(path)) {
                    return;
                }

                let productGuidPath = path.match(/tickets\.\d+\.products\.\d+/)[0] + '.guid';
                let metaGuidPath = path.match(/tickets\.\d+\.products\.\d+\.metaData\.\d+/)[0] + '.guid';
                let reservation = reservations[path.split('.')[1]];
                let productGuid = _.get(requestData, productGuidPath);
                let metaGuid = _.get(requestData, metaGuidPath);

                messages = _.map(messages, m => m.replace(path, MetaData.cached[metaGuid].name));

                _.set(reservation.$errors, [productGuid, metaGuid], messages);
            });
        }
    }).name;
